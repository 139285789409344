import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import TalkContent from "components/talks/TalkContent";
import TweetWrapper from "components/common/TweetWrapper";
import SEO from "components/layout/SEO";
export const _frontmatter = {
  "title": "Basics of Git",
  "path": "talks/git-lecture",
  "shortInfo": "Understanding the basics of why we need git and how to get started with it as a student",
  "position": 3,
  "thumbnail": "./thumbnail.png",
  "videoLength": "1hr 28min",
  "place": "K.C. College",
  "date": "27 May '20"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TalkContent shortInfo="Understanding the basics of why we need git and how to get started with it as a student" title="Basics of Git" youtubeUrl="https://youtu.be/UR8HEC2CXY8?t=229" mdxType="TalkContent">
      <SEO title="Basics of Git - Chaitanya Deorukhkar" description="Understanding the basics of why we need git and how to get started with it as a student" image="talks/git-lecture.png" path="talks/git-lecture" mdxType="SEO" />
      <h2>{`Significance`}</h2>
      <p>{`This talk gave me the opportunity to go back (remotely) to the college I completed by bachelor's degree from and conduct a seminar for the current students in the presence of my college professors.`}</p>
      <h2>{`Motivation`}</h2>
      <p>{`All the while I was in college, our "version control" was pen-drives and emails. At the time this seemed like an okay thing to do since we didn't know any better way to do this. But the moment I started my first job, I realised how much the tech industry relies on git and how we are assumed to know git already. Disappointingly, this was never part of our education system for IT students and it still isn't.`}</p>
      <br />
      <p>{`Since then, I wanted to go back to college and talk about this. Fortunately, the opportunity served itself when one of my ex-professor got in touch with me for conducting a session. It was a no-brainer for me that I'm going to talk about Git and help the students figure out how they can use this for their college projects for better collaboration with each other and prepare them for the world of tech.`}</p>
      <h2>{`Featured Tweets`}</h2>
      <TweetWrapper mdxType="TweetWrapper">
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`And it's done! The whole 1-hour session is on Youtube now :D `}<br parentName="p"></br><br parentName="p"></br>{`So grateful to have gotten an opportunity to introduce 100+ students to git!`}<a parentName="p" {...{
                "href": "https://t.co/98jre2pn9y"
              }}>{`https://t.co/98jre2pn9y`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/NN8NmYD82t"
              }}>{`https://t.co/NN8NmYD82t`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/Rucr65iMOF"
              }}>{`pic.twitter.com/Rucr65iMOF`}</a></p>{`— Chaitanya Deorukhkar (@_anothercoder) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/_anothercoder/status/1265639562765840385"
            }}>{`May 27, 2020`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Sneak peek...I don't think they're going to take me seriously `}<a parentName="p" {...{
                "href": "https://t.co/o5JNqipU1n"
              }}>{`pic.twitter.com/o5JNqipU1n`}</a></p>{`— Chaitanya Deorukhkar (@_anothercoder) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/_anothercoder/status/1265342495250137088"
            }}>{`May 26, 2020`}</a></blockquote></p>
      </TweetWrapper>
    </TalkContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      